import { Layout } from '@/components/layout/Layout';
import { Catalogue } from '@/components/page/common/Catalogue/Catalogue';
import { getPaginationRange, postsPerPage } from '@/lib/blog';
import {
  blogSettingsQuery,
  getAllCategories,
  getAuthorBySlug,
  getCurrentPagePosts,
  navigationCollectionQuery,
  pageQuery,
  siteSettingsQuery,
} from '@/lib/queries';
import { getClient, readToken } from '@/lib/sanityUtils';
import { PageCommonProvider } from '@/lib/usePageCommon';
import { getValidLocale } from '@/lib/utils';

import type { CatalogueProps } from '@/components/page/common/Catalogue/Catalogue';
import type { SanityPageType } from '@/types/sanity';
import type {
  BlogPostPreview,
  BlogSettingsType,
  Navigation_exType,
  SiteSettings,
} from '@/types/shared';
import type { GetServerSideProps, InferGetServerSidePropsType } from 'next';

export const getServerSideProps = (async ({
  query,
  locale = 'en-GB',
  draftMode = false,
  params: { slug } = {},
  resolvedUrl,
}) => {
  const client = getClient(draftMode ? { token: readToken } : undefined, {
    useCdn: true,
  });

  const validLocale = getValidLocale(locale);

  if (!validLocale) {
    return {
      notFound: true,
    };
  }

  const { currentPage, rangeStart, rangeEnd } = getPaginationRange(
    Number(query.page as string),
  );

  const pageType = (resolvedUrl.match(/(category|author)/)?.[0] ??
    'blog') as CatalogueProps['pageType'];

  const [
    listingPage,
    blogSettings,
    siteSettings,
    navigationCollection,
    currentBlogPage,
    allCategories,
    author,
  ] = await Promise.all([
    client.fetch<SanityPageType>(
      pageQuery({ locale: validLocale, slug: slug as string }),
    ),
    client.fetch<BlogSettingsType>(blogSettingsQuery({ locale: validLocale })),
    client.fetch<SiteSettings>(siteSettingsQuery({ locale: validLocale })),

    client.fetch<Navigation_exType[]>(
      navigationCollectionQuery({ locale: validLocale }),
    ),
    client.fetch<{ total: number; posts: BlogPostPreview[] }>(
      getCurrentPagePosts({
        locale: validLocale,
        rangeStart,
        rangeEnd,
        slug: slug as string,
        search: (query.search as string) ?? '',
        joinWith: pageType,
      }),
    ),
    client.fetch(getAllCategories()),
    client.fetch(getAuthorBySlug(slug)),
  ]);

  const maxNumOfPages = Math.ceil(currentBlogPage.total / postsPerPage);

  if (currentPage > 1 && currentPage > maxNumOfPages) {
    return {
      notFound: true,
    };
  }

  return {
    props: {
      posts: currentBlogPage.posts,
      pageCount: maxNumOfPages,
      postCount: currentBlogPage.total as number,
      listingPage,
      blogSettings,
      siteSettings,
      allCategories,
      locale: validLocale,
      draftMode,

      navigationCollection,
      pageType,
      pageSubType: (slug as string) ?? null,
      author,
      token: draftMode ? readToken : '',
    },
  };
}) satisfies GetServerSideProps;

const BlogListingsPage = ({
  listingPage,
  blogSettings,
  siteSettings,
  allCategories,
  posts,
  pageCount,
  postCount,
  draftMode,

  navigationCollection,
  pageType,
  pageSubType,
  author,
}: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  return (
    <PageCommonProvider siteSettings={siteSettings}>
      <Layout
        SEO={listingPage?.SEO}
        siteSettings={siteSettings}
        localeTranslations={true}
        preview={draftMode}
        navigationCollection={navigationCollection}
        layout={{
          firstItemBackground: '--background-warm-base',
          lastItemBackground: '--background-warm-base',
        }}
        dynamicYield={{
          contextPageType: 'CATEGORY',
          contextDataType: 'Blog',
        }}
      >
        <Catalogue
          settings={blogSettings}
          filters={allCategories}
          items={posts}
          pageCount={pageCount}
          postCount={postCount}
          pageType={pageType}
          pageSubType={pageSubType}
          blogAuthor={author}
        />
      </Layout>
    </PageCommonProvider>
  );
};

export default BlogListingsPage;
